<template>
  <div class="home">
  	<div class="form-container flex-wrap">
			<h1 class="mb-3 mt-2 ps-1 pe-1">Scaminox 3Dviewer Tester</h1>

			<p class="instructions">Seleziona il file da caricare e clicca "upload"</p>
			<form @submit.prevent="uploadModel()" class="mb-4 mt-1">
				<div>
				<input type="file" ref="3dModel" @change="handleFileChange" class="form-control"><br>
				<input type="submit" value="upload" class="form-control">
				</div>
			</form>
			
			<div class="model-list" v-if="modelsList.modelID">
				<div class="w-100 table-responsive table-container">
					<table class="table overflow-auto">
						<thead>
							<tr>
								<th>Miniatura</th><th>Nome</th><th>Dimensione</th><th>Azione</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="model,index in modelsList.modelID" :key="index">
								<td><span class="img-container"><img :src="modelsList.thumbnail[index]" class="img-fluid"></span></td>
								<td class="description">{{modelsList.modelName[index]}}</td>
								<td>{{bytesToMBString(modelsList.modelSize[index])}}</td>
								<td><div style="width:80px; display:flex"><a :href="modelsList.url[index]" target="_blank" class="view">View</a><br><a @click="deleteModel(index)" class="delete">Del</a></div></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div class="model-list w-100" v-else>Caricamento modelli..</div>
		</div>
		<div class="loader-container" v-if="isLoading">
			<div class="loader">
		      <div class="dot"></div>
		      <div class="dot"></div>
		      <div class="dot"></div>
		  </div>
    </div>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import axios from 'axios'
/* eslint no-mixed-spaces-and-tabs: "off" */
export default {
  name: 'HomeView',
  data() {
		return {
			selectedFile:null,
  		fileData:null,
  		token:'44f683a84163b3523afe57c2e008bc8c',
  		isLoading:false,
  		modelsList:[]
		};
  },
  mounted() {
  	this.getModelsList();
  },
  methods: {
  	handleFileChange(event) {
  		this.selectedFile = event.target.files[0]
  	},
  	uploadModel() {
  		if (!this.selectedFile) {
  			alert("Please select a 3D model file");
  			return;
  		}
  		this.isLoading = true;
  		const formData = new FormData();
  		formData.append('userFile', this.selectedFile);
  		formData.append('retrievemodel', true);
  		axios	.post(`https://www.3dvieweronline.com/api/v1/models/?token=${this.token}`, formData)
  					.then(() => {
  						this.isLoading = false
  						this.getModelsList()
  					});
  	},
  	getModelsList() {
  		axios	.get(`https://www.3dvieweronline.com/api/v1/models?token=${this.token}&modelID=0`)
  					.then(response => {
							if (response.data && response.data.results)
								this.modelsList = response.data.results
  					});
  	},
		deleteModel(index) {
			let confirmDelete = confirm(`Cancellare il modellino ${this.modelsList.modelName[index]}?`);
			const model = this.modelsList.modelName[index];
			if (confirmDelete) {
				console.log(this.modelsList.modelName[index])
				axios	.delete(`https://www.3dvieweronline.com/api/v1/models/?token=${this.token}&modelID=${this.modelsList.modelID[index]}`)
							.then(response => {
								if (response.data) {
									alert(`Modello ${model} rimosso`);
									this.getModelsList()
								}
							})
			}
		},
		bytesToMBString(bytes) {
			const megabytes = bytes / (1024 * 1024);
			return megabytes.toFixed(2) + " MB";
		}
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,500;0,600;0,700;0,800;1,900&display=swap');
body {
	background-color:#e4e4e4;
	position:relative;
	height:100vh;
	width:100%;
	overflow:hidden;
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;
	h1 {
		text-transform: uppercase;
		font-weight: 500;
		font-size:22px;
		text-align: center;
	}
	.form-container {
		background-color:#FFF;
		display:flex;
		align-items:center;
		justify-content:center;
		max-width:600px;
		width:100%;
		min-height:250px;
		transform:translate(-50%,-50%);
		position:absolute;
		top:50%;
		left:50%;
		overflow-y:auto;
		max-height:100%;
		img {
			max-width:80px;
			transform:rotate(-125deg);
		}
		> form {
			padding:10px;
		}
	}
	.table-container {
		max-width:600px;
		width:100%;
		th {
			text-align: center!important;
		}
		.description {
			font-size:12px; 
			font-weight: 400;
		}
		.img-container {
			border:1px solid #ccc;
			width:80px;
			height:80px;
			display: inline-block;
		}
		a {
			padding:5px;
			color:#FFF;
			border-radius:3px;
			margin:4px;
			text-decoration: none;
			text-transform: uppercase;
			font-size:10px;
			font-weight: 600;
			display: inline-block;
			cursor:pointer;
			&.delete {
				background-color:red;
			}
			&.view {
				background-color:Green;
			}
		}
		td {
			vertical-align: middle;
		}
	}
}
.loader-container {
	z-index:99;
	position:absolute;
	left:0;
	right:0;
	bottom:0;
	top:0;
	background-color:rgba(255,255,255,0.5);
	display:flex;
	align-items:center;
	justify-content:center;
}
.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.dot {
    width: 20px;
    height: 20px;
    background-color: #3498db; /* Change to your desired color */
    border-radius: 50%;
    margin: 0 10px;
    animation: bounce 1.5s infinite ease-in-out;
}

.dot:nth-child(2) {
    animation-delay: 0.5s;
}

.dot:nth-child(3) {
    animation-delay: 1s;
}

@keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
    }
}

</style>
